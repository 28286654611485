import { colors } from '@config/theme'
import { City } from '@libs/types/City.ts'
import { Metro } from '@libs/types/Metro.ts'
import { useAppSelector } from '@store/hooks.ts'
import React from 'react'
import Marquee from 'react-fast-marquee'
import styled from 'styled-components'

interface CitiesMarqueeProps {
  metro?: Metro
  customWidth?: string
  customFontWeight?: number
  customFontSize?: string
  testId?: string
}

export const CitiesMarquee: React.FC<CitiesMarqueeProps> = ({
  metro,
  customWidth,
  customFontWeight,
  customFontSize,
  testId,
}: CitiesMarqueeProps) => {
  const allCities = useAppSelector((state) => state.metro.allCities)
  const selectedMetroCities: City[] = allCities
    .filter((city: City) => city.metro === metro?.id)
    .sort((a: City, b: City) => a.name.localeCompare(b.name))

  if (!selectedMetroCities) {
    return <></>
  }

  return (
    <Marquee
      pauseOnHover={true}
      gradient={true}
      gradientWidth={40}
      data-testid={'scrolling-city'}
      style={{ width: customWidth ?? '100%', minHeight: '1.5rem' }}
    >
      {selectedMetroCities.map((city, index) => (
        <StyledMarqueeItems
          key={index}
          data-testid={`${testId}${index}`}
          style={{ fontWeight: customFontWeight ?? 700, fontSize: customFontSize ?? '1.125rem' }}
        >
          {city.name}
        </StyledMarqueeItems>
      ))}
    </Marquee>
  )
}

// Styled Components
const StyledMarqueeItems = styled.span`
  margin-bottom: 1rem !important;
  text-align: center;
  color: ${colors.brandGray700};
  line-height: 1.5rem;
  margin-right: 7px;
  flex-shrink: 0;

  &:after {
    content: '•';
    margin-left: 7px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`
