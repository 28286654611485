import { Box, Typography } from '@mui/material'
import React from 'react'

import CampResultsSkeleton from './CampResults.skeleton.tsx'

interface Props {
  CampListFilteredLength: number
  isLoading: boolean
}

const CampResults: React.FC<Props> = ({ CampListFilteredLength, isLoading }) => {
  return (
    <>
      <Box sx={{ display: isLoading ? '' : 'none' }}>
        <CampResultsSkeleton />
      </Box>
      <Typography variant="body1" data-testid="camp-results" sx={{ display: isLoading ? 'none' : '' }}>
        {CampListFilteredLength} Results
      </Typography>
    </>
  )
}

export default CampResults
