import { Icons } from '@assets/svg'
import { colors, theme } from '@config/theme'
import { dayLength } from '@decorators/day-length'
import { dateToHumanReadable, priceFormatter } from '@decorators/number-formatter.ts'
import { BussingAddress } from '@libs/types/BussingAddress.ts'
import { Box, ClickAwayListener, Tooltip, tooltipClasses, TooltipProps, Typography, useMediaQuery } from '@mui/material'
import { useAppSelector } from '@store/hooks.ts'
import { Favorite } from '@stories/Icons/Heart/Favorite'
import mixpanel from 'mixpanel-browser'
// import { LocationEnterDialog } from '@stories/Components/LocationEnter/LocationEnterDialog.tsx'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'

import boostedValues from '../../../../../camp_boost_order.json'
import noCampImageFallback from '../../../../assets/media/images/no-camp-image-fallback.jpg'
import { CampBadge } from '../../../../components/CampPage'
import { BadgeInterface } from '../../../../libs/helpers.ts'

const BusIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = Icons['BusIcon']

interface CampItemProps {
  id: number
  image: string
  title: string
  registration_open_date?: string | null
  registration_close_date?: string | null
  badges: BadgeInterface[]
  providerId: number
  providerGoogleReview: number
  programProviderId: number | null
  corporate_provider_name: string
  ages_from: number
  ages_to: number
  dates: string[]
  facility_name: string
  day_length: 'FULL' | 'HALF_AM' | 'HALF_PM' | 'OVERNIGHT'
  daily_price: string | null
  bussing_options?: BussingAddress[] | null
  setFavCampId: () => void
  is_favorite?: boolean
  isBoosted: boolean
  campIndex?: number
  pageIndex?: number
  campPagePosition?: number
}

const CampItemWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 0.625rem;
  margin-block-end: 1.5rem;

  @media ${(props) => props.theme.device['lg']} {
    button:not(.location-link) {
      display: block;
    }

    &:hover button {
      display: block;
    }
  }
`
const CampItemImage = styled.div`
  border-radius: 1rem;
  text-align: center;
  position: relative;

  img {
    width: 100%;
    max-width: 100%;
    border-radius: 0.75rem;
  }
`
const StyledBusIcon = styled(BusIcon)`
  position: absolute;
  bottom: 2.5rem;
  left: -0.65rem;
  z-index: 1;
  @media ${({ theme }) => theme.device['lg']} {
    bottom: 1rem;
  }
`
const CampBadgesWrapper = styled.div<{ $theme: any }>`
  position: relative;
  margin: 0.15rem 0 0.65rem 0;
  top: -3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${({ $theme }) => $theme.device['lg']} {
    position: static;
    display: inline-block;
    overflow-x: unset;
    white-space: pre-wrap;
    padding: 0;
  }

  span {
    margin-right: 0.25rem;
    margin-top: 0.5rem;
  }
`

const CampItemBody = styled.div<{ $theme: any }>`
  position: relative;
  margin: 0.65rem 0 0.65rem 0;
  top: -2rem;
  @media ${({ $theme }) => $theme.device['lg']} {
    position: static;
  }

  p {
    margin-top: 1rem;
    max-width: 100%;
  }

  span {
    margin-right: 0.5rem;
  }

  h2 {
    margin: 0;

    a {
      margin-bottom: 0.25rem;
      color: ${colors.brandGray900};
      text-decoration: none;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;
    }
  }

  h3 {
    margin-top: 0.25rem;
    color: ${colors.brandGray};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
`
const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  color: ${colors.brandGray};
  font-size: 0.875rem;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.5rem;

    strong {
      color: ${colors.brandGray900};
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }
    .location-link {
      color: #175cd3;
      font-weight: 600;
      cursor: pointer;
    }
  }
`

export const CampItem: React.FC<CampItemProps> = ({
  id,
  image,
  title,
  ages_from,
  ages_to,
  badges,
  registration_open_date,
  day_length,
  providerId,
  providerGoogleReview,
  programProviderId,
  corporate_provider_name,
  dates,
  facility_name,
  daily_price,
  bussing_options,
  setFavCampId,
  // is_favorite,
  isBoosted,
  campIndex,
  pageIndex,
  campPagePosition,
}: React.PropsWithChildren<CampItemProps>) => {
  const isLargeScreen: boolean = useMediaQuery(theme.device['md'])
  const zipCode = useAppSelector((state) => state.campFilters.filters.distance.zipCode)
  const [open, setOpen] = React.useState(false)
  const timeoutRef = React.useRef<number | null>(null)

  const handleTooltipClose = () => {
    if (!isLargeScreen) {
      return
    }
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const handleTooltipToggle = () => {
    setOpen((prev) => {
      const newState = !prev

      // If the tooltip is being opened, set a timeout to close it after 5 seconds
      if (newState) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current) // Clear any previous timeout
        }

        timeoutRef.current = window.setTimeout(() => {
          setOpen(false)
          timeoutRef.current = null // Clean up reference
        }, 3000)
      } else {
        // If the tooltip is being closed manually, clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = null
        }
      }

      return newState
    })
  }

  const handleMixPanelEventsForBoostedCamp = () => {
    const isPaidBoost = boostedValues.find((boost) => boost.camp_id === id)

    mixpanel.track('Clicked Search Result', {
      'Camp ID': id,
      'Corporate Provider ID': providerId,
      'Program Provider ID': programProviderId,
      'Position Overall': campIndex,
      'Search Grouping': isBoosted ? (isPaidBoost ? 'paid boosted' : 'unpaid boosted') : 'organic',
      Page: pageIndex,
      'Position On Page': campPagePosition,
    })
  }
  return (
    <ThemeProvider theme={theme}>
      <CampItemWrapper
        id={id.toString()}
        data-testid={`camp-${isBoosted ? 'boosted-' : ''}item-el`}
        data-google-review={providerGoogleReview}
      >
        <CampItemImage>
          <Link
            to={`/camps/${id}`}
            onClick={handleMixPanelEventsForBoostedCamp}
            data-testid={`camp-item-photo-${campIndex}`}
          >
            {bussing_options && bussing_options.length > 0 && <StyledBusIcon />}
            <img
              src={image}
              alt={title}
              loading={'lazy'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = noCampImageFallback
              }}
            />
          </Link>
        </CampItemImage>
        {/*Change active to is_favorite when the time comes*/}
        <Favorite
          active={false}
          onClick={() => {
            setFavCampId()
            mixpanel.track('Camp Heart Button Clicked', {
              'Camp ID': id,
              'Provider ID': providerId,
            })
          }}
        />

        <CampBadgesWrapper $theme={theme}>
          {badges.map((badge, index) => (
            <CampBadge data={badge} key={index} />
          ))}
        </CampBadgesWrapper>
        <CampItemBody $theme={theme}>
          <h2>
            <Link to={`/camps/${id}`} onClick={handleMixPanelEventsForBoostedCamp}>
              {title}
            </Link>
          </h2>
          {isBoosted && (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <BoostedText data-testid="">
                <BootstrapTooltip
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener={!isLargeScreen}
                  disableHoverListener={!isLargeScreen}
                  title={
                    <Box data-testid="boosted_camp_tooltip">
                      <Typography color="inherit" data-testid="boosted_camp_tooltip_title-value" fontWeight={'bold'}>
                        Boosted
                      </Typography>
                      <Typography fontWeight={'regular'} data-testid="boosted_camp_tooltip_text-value">
                        This listing does fit your chosen filters, but it has been boosted to the top of your results by
                        the provider or Camperoni. Boosting does not imply endorsement by Camperoni.
                      </Typography>
                    </Box>
                  }
                  placement={'bottom'}
                  arrow
                >
                  <Box
                    data-testid={`boosted_camp_text_button_${campIndex}`}
                    sx={{ width: 'fit-content', display: 'flex', gap: '4px', alignItems: 'center' }}
                    onClick={isLargeScreen ? undefined : handleTooltipToggle}
                    onMouseEnter={isLargeScreen ? handleTooltipOpen : undefined}
                    onMouseLeave={isLargeScreen ? handleTooltipClose : undefined}
                  >
                    Boosted
                    <Icons.HelpIcon size={16} color={colors.brandGray400} />
                  </Box>
                </BootstrapTooltip>
              </BoostedText>
            </ClickAwayListener>
          )}
          <h3 data-testid="provider-name">{corporate_provider_name}</h3>

          <StyledList>
            <li>
              Ages: {ages_from}-{ages_to}
            </li>

            <li>{dayLength(`${day_length}`)}</li>
            {dates?.length >= 1 && dates[0] && (
              <>
                <li>Start date: {dateToHumanReadable(`${dates[0]}`)}</li>
                <li>End date: {dateToHumanReadable(`${dates[dates.length - 1]}`)}</li>
              </>
            )}
            {registration_open_date && <li>Registration Date: {dateToHumanReadable(`${registration_open_date}`)}</li>}
            <li>{facility_name}</li>
            {!zipCode && (
              <li style={{ display: 'none' }}>
                Distance:&nbsp;
                {/*<button className="location-link" onClick={() => setOpenLocationDialog(true)}>*/}
                {/*  Enter location*/}
                {/*</button>*/}
              </li>
            )}
            <li>
              <strong data-testid="price-value-el">{priceFormatter(`${daily_price}`)}</strong>
            </li>
          </StyledList>
        </CampItemBody>
      </CampItemWrapper>
      {/*<LocationEnterDialog open={openLocationDialog} setOpen={(e) => setOpenLocationDialog(e)} />*/}
    </ThemeProvider>
  )
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} leaveDelay={2000} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#101828',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#101828',
    padding: '0.75rem',
  },
}))

const BoostedText = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 14px;
  margin-top: 0.25rem;
  line-height: 20px;
  color: ${colors.brandGray500};
`
