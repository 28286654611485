import { SpacingProps, theme } from '@config/theme'
import { api } from '@libs/api'
import Eventbus from '@libs/eventbus'
import { navListDataProps } from '@libs/types/Nav.ts'
import { Box } from '@mui/material'
import { applyFilters, resetFilters } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch } from '@store/hooks'
import { Button } from '@stories/Theme/Button/Button'
import { Logo } from '@stories/Theme/Logo/Logo.tsx'
import { FC, useEffect, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { RxCross1, RxHeart } from 'react-icons/rx'
import { Link } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'

import { SideNavWrapper } from './style'

const StyledList = styled.ul<SpacingProps>`
  padding: 1rem;
  font-weight: 600;

  li {
    margin-bottom: 1.5rem;
  }
`

const SideNav: FC<{ navListData: navListDataProps[]; pageTitle?: string }> = ({ navListData, pageTitle }) => {
  const dispatch = useAppDispatch()

  const [navOpen, setNavOpen] = useState<boolean>(false)

  let isLoggedIn = api.isUserLoggedIn()

  useEffect(() => {
    isLoggedIn = api.isUserLoggedIn()
  }, [isLoggedIn])

  const logoutHandler = () => {
    api.logout()
    const nonPrivatePages = !['/settings', '/favorites', '/camps'].includes(location.pathname)
    if (nonPrivatePages) {
      return window.location.reload()
    }
    return (window.location.href = '/login')
  }

  const handleCampSearchClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (pageTitle === 'Camp Search') {
      event.preventDefault()
      handleResetFilters()
    }
  }

  const handleResetFilters = (): void => {
    dispatch(resetFilters())
    Eventbus.trigger(Eventbus.RESET_FILTERS)
    Eventbus.trigger(Eventbus.DISTANCE_CLEAR_FILTER)

    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Reset All Filters',
    })
    dispatch(applyFilters())
  }

  const combinedClickHandler = (
    navListItem: navListDataProps,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    setNavOpen((prev) => !prev)
    if (navListItem.title === 'Camp Search') {
      handleCampSearchClick(event)
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <div className="right-part-mobile">
        {isLoggedIn && (
          <Link to="/favorites">
            <Button icon={<RxHeart fontSize="1rem" />} size="small" variant="secondary" rounded={true} mx="0.4rem" />
          </Link>
        )}
        <Button
          icon={<AiOutlineMenu fontSize="1rem" />}
          size="small"
          variant="secondary"
          rounded={true}
          onClick={() => setNavOpen(true)}
        />
      </div>
      <SideNavWrapper $navOpen={navOpen}>
        <div className="nav-top">
          <div>
            <Logo dataTestId="side_nav_logo_overlay" />
          </div>
          <button type="button" onClick={() => setNavOpen(!navOpen)}>
            <RxCross1 />
          </button>
        </div>
        <StyledList $pt={4}>
          {navListData.map((navListDataItem: navListDataProps) => (
            <li key={navListDataItem.id + 1}>
              <Link
                to={navListDataItem.path}
                target={navListDataItem.target}
                onClick={(event) => combinedClickHandler(navListDataItem, event)}
              >
                {navListDataItem.title}
              </Link>
            </li>
          ))}
        </StyledList>

        <hr style={{ border: '1px solid #D0D5DD' }} />
        <Box style={{ justifyContent: 'space-around', fontWeight: '600', padding: '1rem', display: 'flex' }}>
          <Link to={'/contact'} onClick={() => setNavOpen(!navOpen)}>
            Contact
          </Link>
          <Link to={'/terms'} onClick={() => setNavOpen(!navOpen)}>
            Terms
          </Link>
        </Box>
        <Box style={{ padding: '0 1rem' }}>
          {!isLoggedIn ? (
            <>
              <Link to="/register">
                <Button title={'Sign up'} variant={'primary'} my="0.5rem" fullWidthOnSmallScreen />
              </Link>
              <Link to="/login">
                {' '}
                <Button title={'Login'} variant={'secondary'} fullWidthOnSmallScreen />
              </Link>
            </>
          ) : (
            <>
              <Link to={'/settings'} onClick={() => setNavOpen(!navOpen)}>
                <Button
                  title={'Settings'}
                  variant={'secondary'}
                  my="0.5rem"
                  onClick={() => {}}
                  fullWidthOnSmallScreen
                />
              </Link>
              <Button title={'Logout'} variant={'primary'} my="0.5rem" onClick={logoutHandler} fullWidthOnSmallScreen />
            </>
          )}
        </Box>
      </SideNavWrapper>
    </ThemeProvider>
  )
}

export default SideNav
