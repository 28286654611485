import { colors } from '@config/theme'
import { useAppSelector } from '@store/hooks.ts'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import logoSrc from './logo.svg'

interface LogoProps {
  alt?: string
  dataTestId?: string
}

const LogoLink = styled(Link)`
  align-self: center;
  position: relative;
`

const LogoImage = styled.img`
  // margin-left: 12px;
  // margin-right: 12px;
`

const OverlayTextMetro = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-family: Montserrat, Inter, sans-serif;
  font-weight: 700;
  letter-spacing: 0.45px;
  font-size: 9px;
  color: ${colors.brandPurple};
  max-width: 100px;
  text-align: right;

@media${({ theme }) => theme.device['md']} {
  letter-spacing: 0.36px;
  }
}
`

/**
 * Primary UI component for user interaction
 */
export const Logo = ({ alt = 'Camperoni', dataTestId = 'logo_overlay' }: LogoProps) => {
  const selectedMetro = useAppSelector((state) => state.metro.selectedMetro)

  return (
    <LogoLink to={'/'}>
      <LogoImage src={logoSrc} width={155} height={52} alt={alt} />
      {selectedMetro && (
        <OverlayTextMetro data-testid={dataTestId}>
          <p data-testid={`${dataTestId}_text-value`}>{selectedMetro.name.toUpperCase()}</p>
        </OverlayTextMetro>
      )}
    </LogoLink>
  )
}
